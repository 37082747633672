<template>
  <div
    data-cy="mask"
    class="wrapper"
    @click="$emit('hide')"
  >
    <div
      class="kind_titps"
      @click.stop
    >
      <p>{{ iosTips }}</p>
      <div class="logo_wrapper">
        <img
          class="logo"
          :src="appLogoIcon"
          alt=""
        />
        <img
          class="union"
          src="@/assets/images/apple_union.png"
          alt=""
        />
        <DecodeImage
          class="logo"
          :path="logoIcon"
        />
      </div>
      <div
        data-cy="go-to-app-store"
        class="brand-common-btn"
        @click="$emit('btnClick')"
      >
        <img
          src="@/assets/images/apple_logo_white.png"
          alt=""
        />
        <span>前往苹果商店</span>
      </div>
    </div>
  </div>
</template>
<script>
import DecodeImage from '@/components/common/DecodeImage.vue'
export default {
  components: { DecodeImage },
  props: {
    appLogoIcon: {
      type: String,
      default: '',
    },
    logoIcon: {
      type: String,
      default: '',
    },
    iosTips: {
      type: String,
      default: '',
    },
  },
}
</script>
<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(40, 40, 40, 0.5);
}
.kind_titps {
  width: 287px;
  padding: 16px 26px 20px 26px;
  background: rgba(40, 40, 40, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}
.kind_titps p {
  font-size: 14px;
  line-height: 200%;
  color: #d8d8d8;
}
.logo_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 18px;
  border-radius: 10px;
  border: 0.5px solid #fff;
  background: rgba(102, 102, 102, 0.5);
}
.logo {
  height: 65px;
}
.union {
  width: 30px;
  height: 21px;
}
.brand-common-btn {
  margin-top: 20px;
}
.brand-common-btn img {
  height: 20px;
  margin-right: 10px;
}
</style>
