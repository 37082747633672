<template>
  <div class="download_tips_page">
    <p>下载</p>
    <p class="font_size_18">
      <span class="step">1</span>
      <span>点击下载后，提示文件存在风险</span>
    </p>
    <DecodeImage class="risk_warning" :path="riskWarningImg" />
    <p class="select">选择"继续下载"</p>
    <p>安装</p>
    <p class="font_size_18">
      <span class="step">2</span>
      <span>安装时，提示未知来源应用</span>
    </p>
    <DecodeImage class="risk_warning" :path="riskWarningImg" />
    <p class="select">选择"允许"</p>
  </div>
</template>

<script>
import DecodeImage from '@/components/common/DecodeImage.vue'
import riskWarningImg from '@/assets/images/risk_warning.png'

export default {
  name: 'Steps',
  components: { DecodeImage },

  data() {
    return {
      riskWarningImg,
    }
  },
}
</script>

<style scoped>
.download_tips_page {
  position: relative;
  padding: 0;
  text-align: center;
  margin: 20px 0;
}

.risk_warning {
  margin-top: 25px;
  width: 281px;
}

p {
  color: white;
  font-size: 22px;
  margin-top: 25px;
}

.select {
  font-size: 14px;
  color: #ffd500;
}

.font_size_18 {
  font-size: 18px;
}

.step {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: rgba(29, 27, 27, 0.15);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  margin-right: 8px;
}
</style>
