export const APP_NAME = "Guimi社区";
export const APP_CODE = "guimi";

/**
 * 统计代码注入
 * @desc
 *  {
 *    channel_code: [统计1, 统计2,...]
 *  }
 */
export const STATISTICS_CHANNEL_CODES = {
  "test": ["baidu", "google", "cnzz"],
  "test1": ["test1-1", "test1-2"],
  "test2": ["test2"]
}

export const API_HOSTS = [
  "http://lb-app-api-1372850648.ap-northeast-1.elb.amazonaws.com:18000"
];