var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _vm.androidButtonImg
        ? [
            _c(
              "a",
              {
                staticClass: "brand-common-btn",
                style: _vm.buttonStyle,
                on: { click: _vm.getDownloadUrl },
              },
              [
                _c("DecodeImage", {
                  staticClass: "button-img",
                  attrs: { path: _vm.androidButtonImg },
                }),
              ],
              1
            ),
          ]
        : _c("div", { staticClass: "wrapper__buttons" }, [
            _c(
              "a",
              {
                staticClass: "brand-common-btn",
                style: _vm.buttonStyle,
                attrs: { "data-cy": "download-button" },
                on: { click: _vm.getDownloadUrl },
              },
              [
                _vm.buttonBackgroundImg
                  ? _c("DecodeImage", {
                      staticClass: "button-img",
                      attrs: { path: _vm.buttonBackgroundImg },
                    })
                  : _vm._e(),
                _c("span", { style: _vm.buttonBeforeStyle }),
                _c("span", { staticClass: "button-title" }, [_vm._v("下载")]),
                _c("span", { style: _vm.buttonAfterStyle }),
              ],
              1
            ),
          ]),
      _c("div", { staticClass: "tip_text" }, [
        _c(
          "a",
          {
            staticClass: "solution",
            attrs: {
              "data-cy": "solution-link",
              href: "/solution.html",
              target: "_blank",
            },
            on: { click: _vm.clickHelpDoc },
          },
          [_vm._v(" 成人APP会被误报病毒 忽略即可(查看帮助) ")]
        ),
      ]),
      _c("a", {
        ref: "android_download_link",
        staticStyle: { display: "none" },
        attrs: { href: _vm.android_download_url },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }