export const APP_NAME = '大象传媒';
export const APP_CODE = "xindaxiang";

export const BD_STATISTICS_CONFIG = [
  {
    channel_codes: ['dx06'],
    script_content: `var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?83f77c1e328455d621a353f32fc6f7e7";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();`
  }
]

export const API_HOSTS = [
  "https://api.bjkmy.net",
  "https://api.jtshuzhiwa.com",
  "https://appapi.mnghx.info",
  "https://appapi.17gwl.com",
  "https://appapi.zdxclr.com"
];
