import { ajax } from "../utils";
import { API_HOST, API_PREFIX, ENV } from "../config";

export const httpGet = (path, data = {}, hideLoading = false) => {
  const API_URL = (ENV === 'development') ? API_HOST : API_PREFIX
  const url = path.startsWith("http") ? path : API_URL + path;
  return ajax({
    url,
    data,
    hideLoading
  });
};
export const httpPost = (path, data = {}, hideLoading = false) => {
  const API_URL = (ENV === 'development') ? API_HOST : API_PREFIX
  const url = path.startsWith("http") ? path : API_URL + path;
  return ajax({
    url,
    type: "POST",
    data,
    hideLoading
  });
};
