import aesjs from 'aes-js'
import UaParser from 'ua-parser-js'
import DeviceDetector from 'device-detector-js'

import {
  IS_iOS,
  STATISTICS_HOST,
  API_EVENT_HOST,
  UV_COOKIE_NAME,
  DECODE_MEDIA_KEY,
  CLICK_DOWNLOAD, ENV, API_HOST, API_PREFIX,
} from './config'

export function isPC() {
  //是否为PC端
  let userAgentInfo = navigator.userAgent
  let Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
  ]
  let flag = true
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}

/**
 * 获取url参数值
 * @param {string} name 查询参数名
 */
export function getQueryString(name) {
  var obj = {},
    reg = /([^?=&#]+)(?:=([^?=&#]+)?)/g
  window.location.href.replace(reg, function () {
    var key = arguments[1]
    var arg2 = arguments[2] ? decodeURIComponent(arguments[2]) : null
    obj[key] = arg2
  })
  return obj[name]
}

export function getQueryValues() {
  let path = location.pathname.substr(1)
  let starts = path.slice(0, 2)
  if (starts === 'i-') {
    // 先截取邀请码-邀请码格式只能是大写字母和数字组合
    let invite_code = path.slice(2)
    let special_index = -1;
    ['Null', 'None'].some(word => {
      if (invite_code.indexOf(word) > -1) {
        return special_index = invite_code.indexOf(word)
      }
    })
    // 判断第7位是否是大写字母或者数字
    if (/[A-Z0-9]/.test(invite_code.slice(6, 7))) {
      // 邀请码是7位
      if (special_index > -1 && special_index === 6) {
        // 实际邀请码上跟着有Null None，第7位是N,需要截取
        invite_code = invite_code.slice(0, 6)
      } else {
        invite_code = invite_code.slice(0, 7)
      }
    } else {
      // 邀请码是6位
      invite_code = invite_code.slice(0, 6)
    }
    return {
      channel_code: 'share',
      invite_code,
    }
  }
  let result = {}
  let channel_code =
    getQueryString('channel_code') || getQueryString('channelCode') || 'web'
  let invite_code =
    getQueryString('invite_code') || getQueryString('invitation_code') || ''
  invite_code = decodeURIComponent(invite_code)

  if (channel_code && channel_code !== 'undefined' && channel_code !== 'null') {
    result.channel_code = channel_code
  } else {
    result.channel_code = 'web'
  }

  if (invite_code && invite_code !== 'undefined' && invite_code !== 'null') {
    result.invite_code = invite_code
  } else {
    result.invite_code = ''
  }
  return result
}

/**
 * 吐司提示
 * @param {string} content 提示内容
 * @param {number} duration 显示时长，秒，默认为2
 */
export function showToast(content, duration) {
  content = content || '操作成功'
  duration = duration || 2
  let aSpan = document.createElement('span')
  aSpan.className = 'created_toast'
  aSpan.innerHTML = content
  document.body.appendChild(aSpan)

  let timeout = setTimeout(function () {
    document.body.removeChild(aSpan)
    clearTimeout(timeout)
    timeout = null
  }, duration * 1000)
}

/**
 * 点击复制内容到粘贴板
 * @param {string} content 需要复制的内容
 * @param {string} tips    复制成功后的提示内容
 */
export function clickCopy(content, tips, hideTips, cb) {
  let parser = new UaParser()
  let { os: { name, version } } = parser.getResult()
  if (name === 'Android' && version >= 13) {
    // android13系统去除复制
    return
  }
  tips = tips || '复制成功'
  hideTips = hideTips || false

  // 新增 删除机制
  if (document.getElementsByClassName('copy_text')[0]) {
    document.getElementsByClassName('copy_text')[0].remove()
  }

  let input = document.createElement('input')
  input.setAttribute('readonly', 'readonly')
  input.setAttribute('value', content)
  document.body.appendChild(input)
  input.focus()
  input.setSelectionRange(0, 9999)
  if (document.execCommand('copy')) {
    document.execCommand('copy')
    !hideTips && showToast(tips)
    cb && cb()
  }
  input.setAttribute('style', 'display: none')
  input.setAttribute('class', 'copy_text')
}

function showLoading() {
  let loadingEl = document.createElement('div')
  let fadingCircle = document.createElement('div')
  loadingEl.className = 'created_loading'
  fadingCircle.className = 'fading-circle'
  for (let i = 1; i < 13; i++) {
    let circle = document.createElement('div')
    circle.className = 'circle circle' + i
    fadingCircle.appendChild(circle)
  }
  loadingEl.appendChild(fadingCircle)
  document.body.appendChild(loadingEl)
}

function removeLoading() {
  let loadingEl = document.querySelector('.created_loading')
  if (loadingEl) {
    document.body.removeChild(loadingEl)
  }
}

/**
 * 发起http请求
 * @param {Object}    options 请求参数
 * @param {string}    options.url    请求地址
 * @param {string}    options.type  请求方式:get/post,默认get
 * @param {Object}    options.data  请求的参数
 * @param {string}    options.dataType 请求的数据格式
 * @param {Object}    options.headers 请求的数据格式
 * @param {Function}  options.success  请求成功的回调函数
 * @param {Function}  options.error  请求失败的回调函数
 */
export function ajax(options) {
  removeLoading()

  options = options || {}

  options.type = options.type || 'get'
  options.data = options.data || {}
  options.dataType = options.dataType || 'text'
  options.hideLoading = options.hideLoading || false
  options.timeout = options.timeout || 60 * 1000

  const deviceDetector = new DeviceDetector()
  const deviceData = deviceDetector.parse(navigator.appVersion)

  // X-Device-Info: [Device_Manufacturer] / [Device_Model] / [SDK_Version]
  options.headers = {
    'X-Device-Info': `${deviceData.device.brand}/${deviceData.device.model}/${deviceData.os.version}`,
  }

  let xhr = new XMLHttpRequest()

  xhr.timeout = options.timeout

  // 数据组装
  let arr = []
  for (let name in options.data) {
    // 利用encodeURIComponent()来解决参数名/值是汉字时的异常情况
    let i =
      encodeURIComponent(name) + '=' + encodeURIComponent(options.data[name])
    arr.push(i)
  }
  let strData = arr.join('&')

  if (options.type.toUpperCase() == 'POST') {
    xhr.open('POST', options.url, true)
    xhr.setRequestHeader('content-type', 'application/x-www-form-urlencoded')
    if (options.headers) {
      for (let p in options.headers) {
        xhr.setRequestHeader(p, options.headers[p])
      }
    }
    xhr.send(strData)
  } else {
    xhr.open('GET', options.url + '?' + strData, true)
    if (options.headers) {
      for (let p in options.headers) {
        xhr.setRequestHeader(p, options.headers[p])
      }
    }
    showLoading()
    xhr.send()
  }

  if (!options.hideLoading) {
    showLoading()
  }

  return new Promise((resolve, reject) => {
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        removeLoading()
        if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
          // 默认dataType为text时：
          let data = xhr.responseText

          // 判断dataType
          switch (options.dataType) {
            case 'text':
            case 'json':
              data = JSON.parse(data)
              break
            case 'xml':
              data = xhr.responseXML
              break
          }

          resolve(data)
        } else {
          reject('ajax error')
        }
      }
    }
  })
}

export const docCookies = {
  getItem: function (sKey) {
    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            '(?:(?:^|.*;)\\s*' +
            encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') +
            '\\s*\\=\\s*([^;]*).*$)|^.*$',
          ),
          '$1',
        ),
      ) || null
    )
  },
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
      return false
    }
    let sExpires = ''
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires =
            vEnd === Infinity
              ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
              : '; max-age=' + vEnd
          break
        case String:
          sExpires = '; expires=' + vEnd
          break
        case Date:
          sExpires = '; expires=' + vEnd.toUTCString()
          break
        default:
          break
      }
    }
    document.cookie =
      encodeURIComponent(sKey) +
      '=' +
      encodeURIComponent(sValue) +
      sExpires +
      (sDomain ? '; domain=' + sDomain : '') +
      (sPath ? '; path=' + sPath : '') +
      (bSecure ? '; secure' : '')
    return true
  },
}

export function setUserIdentifier() {
  let user_identifier = null
  user_identifier = docCookies.getItem(UV_COOKIE_NAME)
  console.log(user_identifier)
  if (
    !user_identifier ||
    user_identifier === 'null' ||
    user_identifier === 'undefined'
  ) {
    user_identifier = Date.now() + '_' + Math.random().toFixed(10)
    docCookies.setItem(UV_COOKIE_NAME, user_identifier, Infinity)
  }
}

export function uploadVisitEvent(app_code = 'apple') {
  let { channel_code } = getQueryValues()
  let user_identifier = docCookies.getItem(UV_COOKIE_NAME)
  console.log('user_identifier')
  if (
    !user_identifier ||
    user_identifier === 'null' ||
    user_identifier === 'undefined'
  )
    return
  var data = {
    app_code,
    channel_code,
    user_identifier,
    referrer_url: document.referrer,
    url: window.location.href,
    device_platform: IS_iOS ? 'iOS' : 'Android',
  }
  const API_URL = (ENV === 'development') ? STATISTICS_HOST : API_EVENT_HOST
  var option = {
    url: API_URL + '/m_event/mobile/web_page',
    type: 'post',
    data: data,
    dataType: 'json',
    hideLoading: true,
    success: function (res) {
      console.log(res)
    },
    error: function (err) {
      console.log(err)
    },
  }
  ajax(option)
}

export function uploadDownloadEvent(
  app_code = 'apple',
  event_name,
  download_type,
  extra = {},
) {
  if (!event_name || !download_type) return

  let { channel_code } = getQueryValues()

  let user_identifier = docCookies.getItem(UV_COOKIE_NAME)
  console.log('user_identifier')
  if (!user_identifier || user_identifier === 'null') return
  let data = {
    channel_code,
    event_name,
    user_identifier,
    download_type,
    app_code,
    ...extra,
    referrer_url: document.referrer,
  }
  // 加入google上报
  let ga_event_name = event_name === CLICK_DOWNLOAD ? `${event_name}_${download_type}` : event_name
  gtag && gtag('event', ga_event_name, data)

  const API_URL = (ENV === 'development') ? STATISTICS_HOST : API_EVENT_HOST

  let option = {
    url: API_URL + '/m_event/mobile/event',
    type: 'post',
    data: data,
    dataType: 'json',
    hideLoading: true,
    success: function (res) {
      console.log(res)
    },
    error: function (err) {
      console.log(err)
    },
  }
  ajax(option)
}

export function openNewPageInSafari(url) {
  const random = Math.random().toFixed(6)
  const api = `https://www.${random}.${random}.com`
  ajax({
    url: api,
    hideLoading: true,
    timeout: 10,
    success: function (res) {
      console.log(res)
    },
    error: function (err) {
      var t = setTimeout(function () {
        document.location.href = url
        clearTimeout(t)
        t = null
      }, 100)
    },
  }).catch(err => {
    var t = setTimeout(function () {
      document.location.href = url
      clearTimeout(t)
      t = null
    }, 100)
  })
}

export function isSafari() {
  var ua = navigator.userAgent.toLowerCase()
  if (
    ua.indexOf('applewebkit') > -1 &&
    ua.indexOf('mobile') > -1 &&
    ua.indexOf('safari') > -1 &&
    ua.indexOf('linux') === -1 &&
    ua.indexOf('android') === -1 &&
    ua.indexOf('chrome') === -1 &&
    ua.indexOf('ios') === -1 &&
    ua.indexOf('browser') === -1
  ) {
    return true
  } else {
    return false
  }
}

export function decryptImage(path, cb) {
  fetch(path).then(res => res.blob()).then(blob => {
    const key = aesjs.utils.utf8.toBytes(DECODE_MEDIA_KEY)
    const aesEcb = new aesjs.ModeOfOperation.ecb(key)

    let reader = new FileReader()
    reader.readAsArrayBuffer(blob)
    reader.addEventListener('loadend', () => {
      // reader.result 包含转化为类型数组的blob
      let arr = new Uint8Array(reader.result)

      try {
        let decryptedBytes = aesEcb.decrypt(arr)
        let blob = new Blob([decryptedBytes], {
          type: 'image/*',
        }) // 传入一个合适的MIME类型
        let res_url = URL.createObjectURL(blob)
        // self.src = res_url;
        cb && cb(res_url)
      } catch (e) {
        console.log(e)
      }
    })
  })
}

export function triggerDownloadApk(downloadUrl) {
  const iframe = document.createElement('iframe')
  iframe.src = downloadUrl
  iframe.style.display = 'none'
  document.body.appendChild(iframe)
}
