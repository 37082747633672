export const APP_NAME = "本色视频";
export const APP_CODE = "bs";

export const SLOGAN_LINE_1 = "男儿本色，有我即可";
export const SLOGAN_LINE_2 = "成人抖音-热血男儿必备";

export const SLIDE_IMGS = ["slide1.jpg", "slide2.jpg", "slide3.jpg"]; // 轮播图图片名称

export const MODE_QUERY_NAME = 'm'; // 这个是 查询参数的 key
export const MODE_QUERY_VALUE = 'n'; // 这个是 查询参数的 value
export const MODE_SLIDE_IMGS = {
    'n': ['n_slide1.jpg', 'n_slide2.jpg', 'n_slide3.jpg']
}

export const IOS_DOWNLOAD_TIPS = "受苹果政策影响，APP无法上架苹果商店，请按教程安装";
export const ANDROID_DOWNLOAD_TIPS = "成人应用被误报病毒，我们靠广告盈利，不是病毒！";

export const MODE_APP_NAME = "本色直播";


export const MODE_SLOGAN_LINE_1 = "男儿本色，有我即可";
export const MODE_SLOGAN_LINE_2 = "成人抖音-热血男儿必备";

export const BOTTOM_BANNERS = [
  {
    img_name: "banner1.jpg",
    mode_n_img_name: "n_banner1.jpg",
    title: "美女欲拒还迎,面对激情反而迎男而上!",
    mode_n_title: ""
  },
  {
    img_name: "banner2.jpg",
    mode_n_img_name: "n_banner2.jpg",
    title: "高清自拍清纯美女学生妹制服技术太棒了，吃不够大JJ，淫叫不断！",
    mode_n_title: ""
  },
  {
    img_name: "banner3.jpg",
    mode_n_img_name: "n_banner3.jpg",
    title: "黑丝长腿嫩乳翘臀美女露逼诱惑！",
    mode_n_title: ""
  }
];

export const STATISTICS_CHANNEL_CODES = ['bs1017']
export const STATISTICS_INSERT_CONTENT = '%3Cscript src="https://s9.cnzz.com/z_stat.php%3Fid%3D1279355801" type="text/javascript"%3E%3C/script%3E'

export const BD_STATISTICS_CONFIG = [];

export const API_HOSTS = [
  "https://api.bjkmy.net",
  "https://api.jtshuzhiwa.com",
  "https://appapi.mnghx.info",
  "https://appapi.17gwl.com",
  "https://appapi.zdxclr.com"
];