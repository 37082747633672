<template>
  <div class="join-group">
    <DecodeImage :path="JoinLogo" alt="" class="logo" />
    <h3 class="title">老司机火爆交流群(无需翻墙)</h3>
    <a
      data-cy="join-group-link"
      id="js_group_link"
      target="_blank"
      :href="CHAT_GROUP_URL"
      class="join-text"
      @click="onClickJoinGroup"
      >点击加入>></a
    >
  </div>
</template>

<script>
import { CHAT_GROUP_URL, IOS_H5 } from '../../../../config'
import { CLICK_JOIN_GROUP } from '../../../../config/event_name'
import { uploadDownloadEvent } from '../../../../utils'
import JoinLogo from '@/assets/images/join_logo.png'
import DecodeImage from '@/components/common/DecodeImage.vue'

export default {
  components: { DecodeImage },
  props: {
    appCode: {
      type: String,
      default: 'apple',
    },
  },
  data() {
    return {
      CHAT_GROUP_URL,
      JoinLogo,
    }
  },
  methods: {
    onClickJoinGroup() {
      uploadDownloadEvent(this.appCode, CLICK_JOIN_GROUP, IOS_H5)
    },
  },
}
</script>

<style scoped>
.join-group {
  margin: 24px auto 0;
  padding: 16px 0 24px;
  width: 260px;
  text-align: center;
  font-size: 16px;
  color: #fff;
}

.logo {
  width: 56px;
  height: 56px;
}

.title {
  color: #fff;
  font-size: 18px;
  margin: 12px 0;
}

.join-text {
  color: #ffd500;
  font-size: 16px;
}
</style>
