export const APP_NAME = '陌陌视频';
export const APP_CODE = "momo";
export const SLOGAN = "「陌陌」成人版-看片约炮聊骚神器";
export const SLIDE_IMGS = ["slide1.jpg", "slide2.jpg", "slide3.jpg"]; // 轮播图图片名称

//const UMeng_STATISTICS_INSERT_CONTENT = '%3Cscript src="https://v1.cnzz.com/z_stat.php%3Fid%3D1279762682" type="text/javascript"%3E%3C/script%3E';
const UMeng_STATISTICS_INSERT_CONTENT = '';

const GA_MEASUREMENT_ID = "G-1B7B063TML";

export const insertStatistic = () => {
  let scriptSrc = document.createElement("script");
  scriptSrc.setAttribute("async", "async");
  scriptSrc.setAttribute(
    "src",
    `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`
  );
  let script = document.createElement("script");
  script.innerHTML = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());


  gtag('config', 'G-1B7B063TML');`;


  document.head.insertBefore(scriptSrc, document.head.firstChild);
  document.head.insertBefore(script, document.head.children[1]);
  // umeng
  document.write(unescape(UMeng_STATISTICS_INSERT_CONTENT));
};

export const API_HOSTS = [
  "https://api.bjkmy.net",
  "https://api.jtshuzhiwa.com",
  "https://appapi.mnghx.info",
  "https://appapi.17gwl.com",
  "https://appapi.zdxclr.com"
];