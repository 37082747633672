<template>
  <div v-if="backgroundImageMode" v-show="src" :style="{backgroundImage: 'url(' + src + ')' }">
    <slot></slot>
  </div>

  <img v-else v-show="src" :src="src" alt=""/>
</template>

<script>
import { decryptImage } from '../../../utils'

export default {
  props: {
    path: {
      type: String,
      default: '',
    },
    backgroundImageMode: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    path() {
      if (process.env.VUE_APP_ENV === 'local') {
        this.src = this.path
      }
      else {
        decryptImage(this.path, url => {
          this.src = url
        })
      }
    },
  },
  data() {
    return {
      src: '',
    }
  },
  mounted() {
    if (!this.path) return
    if (process.env.VUE_APP_ENV === 'local') {
      this.src = this.path
    }
    else {
      decryptImage(this.path, url => {
        this.src = url
      })
    }
  },
}
</script>

<style lang="css" scoped></style>
