<template>
  <div class="wrapper">
    <div
      data-cy="download-button"
      class="brand-common-btn"
      :style="buttonStyle"
      @click="showQRCode"
    >
      <DecodeImage v-if="buttonImg" :path="buttonImg" />
      <DecodeImage
        v-if="buttonBackgroundImg"
        class="button-img"
        :path="buttonBackgroundImg"
      />
      <span :style="buttonBeforeStyle" />
      <span class="button-title">立即下载</span>
      <span :style="buttonAfterStyle" />
    </div>
    <div data-cy="mask" class="mask col_jac_center" v-show="QRCodeShow">
      <h3>请使用手机扫码</h3>
      <div class="qrcode_box">
        <div id="js_qrcode" class="qrcode"></div>
      </div>
      <p>请勿使用微信、QQ扫码，以免无法访问</p>
    </div>
  </div>
</template>

<script>
import DecodeImage from '@/components/common/DecodeImage.vue'

export default {
  components: { DecodeImage },
  props: {
    buttonStyle: {
      type: Object,
      default: () => ({}),
    },
    buttonImg: {
      type: String,
      default: '',
    },
    buttonBeforeStyle: {
      type: Object,
      default: () => ({}),
    },
    buttonAfterStyle: {
      type: Object,
      default: () => ({}),
    },
    buttonBackgroundImg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      QRCodeShow: false,
    }
  },
  methods: {
    showQRCode() {
      const QRCode = require('qrcodejs2')
      let qrcode = new QRCode(document.getElementById('js_qrcode'), {
        text: window.location.href,
        correctLevel: QRCode.CorrectLevel.H,
      })
      this.QRCodeShow = true
    },
  },
}
</script>

<style scoped>
.mask {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.brand-common-btn {
  width: 70%;
  position: relative;
}

.button-title {
  z-index: 3;
}

.button-img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.qrcode_box {
  padding: 16px;
  background: #fff;
  margin: 0.16rem 0;
}

.mask h3 {
  color: #fff;
  font-size: 18px;
}

.mask p {
  color: red;
  font-size: 16px;
}
</style>
