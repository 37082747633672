<template>
  <div class="tencent_content">
    <decode-image :path="require('@/assets/images/tencent_1.png')" />
    <div class="tencent_tips_option">
      <div class="ac_flex">
        <span class="option_two jac_flex">2</span>
        <div class="domain_box">
          <p>或复制链接到浏览器打开：</p>
          <p class="domain">{{ url }}</p>
        </div>
      </div>
      <div id="js_copy_domain" class="copy_domain jac_flex" @click="copyLink">
        点击复制链接
      </div>
    </div>
  </div>
</template>

<script>
import { clickCopy } from '../../../../utils'
import DecodeImage from '../../../components/common/DecodeImage.vue'
export default {
  components: { DecodeImage },
  data() {
    return {
      url: window.location,
    }
  },
  methods: {
    copyLink() {
      clickCopy(this.url, '复制成功，请到浏览器打开')
    },
  },
}
</script>

<style scoped>
.tencent_content {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
}
.tencent_content img {
  width: 4rem;
}
</style>
