var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "join-group" },
    [
      _c("DecodeImage", {
        staticClass: "logo",
        attrs: { path: _vm.JoinLogo, alt: "" },
      }),
      _c("h3", { staticClass: "title" }, [
        _vm._v("老司机火爆交流群(无需翻墙)"),
      ]),
      _c(
        "a",
        {
          staticClass: "join-text",
          attrs: {
            "data-cy": "join-group-link",
            id: "js_group_link",
            target: "_blank",
            href: _vm.CHAT_GROUP_URL,
          },
          on: { click: _vm.onClickJoinGroup },
        },
        [_vm._v("点击加入>>")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }