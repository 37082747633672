<template>
  <DecodeImage
    class="common-page-content"
    :path="backgroundImg"
    :background-image-mode="true"
  >
    <div class="tutorial-logo">
      <slot name="logo">
        <DecodeImage class="logo" :path="logoImg" />
      </slot>
    </div>

    <div class="tutorial-title">
      <slot name="title">
        <div class="common-page-title">{{ title }}</div>
      </slot>
    </div>

    <div class="tutorial-steps">
      <slot name="steps"><Steps /></slot>
    </div>

    <div class="tutorial-download">
      <slot name="download">
        <div class="brand-common-btn" @click="download">知道了，立即下载</div>
      </slot>
    </div>

    <div class="tutorial-join-group">
      <slot name="join-group">
        <JoinGroup :app-code="appCode" />
      </slot>
    </div>
  </DecodeImage>
</template>
<script>
import Steps from './Steps.vue'
import DecodeImage from '@/components/common/DecodeImage.vue'
import JoinGroup from '@/components/common/join-group/index.vue'
import { getQueryValues, uploadDownloadEvent } from '@/../utils'
import { CLICK_DOWNLOAD, IOS_LIGHT } from '@/../config'
import { getIOSMobileConfig } from '@/service'

export default {
  name: 'AndroidTutorial',
  components: { JoinGroup, DecodeImage, Steps },
  props: {
    backgroundImg: {
      type: String,
      default: require('@/assets/images/common_bg.png'),
    },
    logoImg: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '安卓安装教程',
    },
    appCode: {
      type: String,
      default: '',
    },
  },
  methods: {
    getMobileConfigUrl() {
      const { channel_code, invite_code } = getQueryValues()
      let url = `/apis/m_appstore/iOS/downloadApp?app_code=${this.appCode}&channel_code=${channel_code}`
      if (invite_code) {
        url = url + `&invite_code=${invite_code}`
      }
      return url
    },
    async download() {
      uploadDownloadEvent(this.appCode, CLICK_DOWNLOAD, IOS_LIGHT)
      const url = this.getMobileConfigUrl()
      try {
        await getIOSMobileConfig()
      } catch (error) {
        let t = setTimeout(function () {
          document.location.href = url
          clearTimeout(t)
          t = null
        }, 100)
      }
    },
  },
}
</script>

<style scoped>
.tutorial-logo {
  margin-bottom: 20px;
}

.tutorial-download .brand-common-btn {
  width: 260px;
  margin: auto;
}
</style>
