import { httpGet, httpPost } from '../../api'
import { UV_COOKIE_NAME } from '../../config'
import { docCookies, getQueryValues } from '../../utils'

export const getIOSActiveButtons = (app_code = 'apple') => {
  const { channel_code, invite_code } = getQueryValues()
  return httpGet('/m_appstore/iOS/downloadBtn', {
    channel_code,
    app_code,
    invite_code,
  })
}

export const getIOSMobileConfig = () => {
  return httpGet('https://www.baidu.com')
}

export const getAndroidDownloadUrl = (app_code = 'apple') => {
  const { channel_code, invite_code } = getQueryValues()
  return httpGet('/m_appstore/channel_apks_url', {
    channel_code,
    app_code,
    invite_code,
  })
}
export const getBehaviorVerificationCode = () => {
  const device_label = docCookies.getItem(UV_COOKIE_NAME)
  return httpGet('/m_appstore/tf_behavior_verification', { device_label })
}
export const postBehaviorVerificationCode = (app_code, clicked_position) => {
  const device_label = docCookies.getItem(UV_COOKIE_NAME)
  return httpPost('/m_appstore/tf_behavior_verification', {
    app_code,
    device_label,
    clicked_position,
  })
}
export const getShellpackagepass = () => {
  return httpGet('/m_appstore/shell_package_pass')
}
