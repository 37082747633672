export const APP_NAME = '撸视频';
export const APP_CODE = "lu";
export const SLOGAN = "随时随地，想撸就撸";
export const SLIDE_IMGS = ["slide1.jpg", "slide2.jpg", "slide3.jpg"]; // 轮播图图片名称

export const STATISTICS_CHANNEL_CODES = ['LUSP010']
//const STATISTICS_INSERT_CONTENT = '%3Cscript src="https://s4.cnzz.com/z_stat.php%3Fid%3D1279723998" type="text/javascript"%3E%3C/script%3E'
export const STATISTICS_INSERT_CONTENT = 'https://s4.cnzz.com/z_stat.php%3Fid%3D1279723998'

export const API_HOSTS = [
	"https://api.bjkmy.net",
	"https://api.jtshuzhiwa.com",
	"https://appapi.mnghx.info",
	"https://appapi.17gwl.com",
	"https://appapi.zdxclr.com"
  ];