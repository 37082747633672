import { IOS_H5 } from "./index";

export const IOS_BUTTON_TYPES = [
  {
    title: "经典版下载",
    tips: "备用",
    type: IOS_H5,
    link: "ios_light.html"
  },
  {
    title: "一般版下载",
    tips: "方便快速，稳定使用",
    type: "iOS_default"
  }
];
