export const APP_NAME = '91视频';
export const APP_CODE = "apple";

export const STATISTICS_CHANNEL_CODES = {
  "zhizhu": ["https://s4.cnzz.com/z_stat.php?id=1280427640&web_id=1280427640"]
}

export const API_HOSTS = [
  "https://appapi.mnghx.info",
  "https://appapi.17gwl.com",
  "https://appapi.zdxclr.com",
];