<template>
  <DecodeImage
    :path="backgroundImg"
    class="common-page-content"
    :background-image-mode="true"
  >
    <div>
      <div class="solution-logo">
        <slot name="logo">
          <DecodeImage
            class="logo"
            :path="logoImg"
          />
        </slot>
      </div>

      <div class="solution-title">
        <slot name="title">
          <div class="common-title">{{ title }}</div>
        </slot>
      </div>

      <div class="solution-phone-types">
        <slot name="phone-types">
          <div
            v-for="type in phoneTypes"
            :key="type.name"
            @click="clickShowPopup(type.src)"
          >
            <button
              class="brand-common-btn"
              data-cy="mobile-tutorial"
            >
              {{ type.name }}手机教程
            </button>
          </div>
        </slot>
      </div>
    </div>

    <div class="solution-join-group">
      <slot name="join-group">
        <p class="tips">{{ tipsText }}</p>
        <a
          :href="CHAT_GROUP_URL"
          target="_blank"
          data-cy="join-group-link"
        >
          <button
            class="brand-common-btn"
            @click="onJoinClick"
          >
            点击加入
          </button>
        </a>
      </slot>
    </div>

    <van-popup
      v-model="showPopup"
      :style="{ width: '90%' }"
      get-container="#app"
      data-cy="mask"
    >
      <DecodeImage
        :path="popupImg"
        @click.native.t.stop="clickClosePopup"
      />
    </van-popup>
  </DecodeImage>
</template>

<script>
import { CHAT_GROUP_URL, ANDROID } from '@/../config'
import { CLICK_JOIN_GROUP } from '@/../config/event_name'
import { uploadDownloadEvent } from '@/../utils'
import DecodeImage from '@/components/common/DecodeImage'
import Vue from 'vue'
import { Popup } from 'vant'
import 'vant/lib/index.css'

Vue.use(Popup)

export default {
  name: 'solution',
  components: { DecodeImage },
  props: {
    appCode: {
      type: String,
      default: 'apple',
    },
    backgroundImg: {
      type: String,
      default: require('@/pages/momo/index/assets/images/common_bg.png'),
    },
    logoImg: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '报毒解决教程',
    },

    tipsText: {
      default: '若有其他问题，请加官方交流群咨询',
    },
  },
  data() {
    return {
      CHAT_GROUP_URL,
      phoneTypes: [
        {
          name: '华为/荣耀',
          src: require('@/assets/images/solution_huawei.png'),
        },
        {
          name: 'OPPO',
          src: require('@/assets/images/solution_oppo.png'),
        },
        {
          name: 'VIVO',
          src: require('@/assets/images/solution_vivo.png'),
        },
        {
          name: '小米',
          src: require('@/assets/images/solution_xiaomi.png'),
        },
        {
          name: '魅族',
          src: require('@/assets/images/solution_meizu.png'),
        },
      ],
      showPopup: false,
      popupImg: '',
    }
  },
  methods: {
    onJoinClick() {
      uploadDownloadEvent(this.appCode, CLICK_JOIN_GROUP, ANDROID)
    },
    clickShowPopup(img) {
      this.showPopup = true
      this.popupImg = img
    },
    clickClosePopup() {
      this.showPopup = false
      this.popupImg = ''
    },
  },
}
</script>

<style scoped>
.common-page-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.solution-title .common-title {
  width: 227px;
  font-size: 0.32rem;
  margin: 25px auto 32px;
  color: #fff;
}

.solution-phone-types {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 260px;
  margin: auto;
}

.solution-phone-types .brand-common-btn {
  width: 100%;
}

.solution-join-group {
  width: 260px;
  margin: 28px auto 0;
  color: #fff;
}

.solution-join-group .brand-common-btn {
  width: 100%;
}

.tips {
  font-size: 14px;
  margin-bottom: 10px;
  text-decoration: underline;
}
</style>
