var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      {
        staticClass: "brand-common-btn",
        style: _vm.buttonStyle,
        attrs: { "data-cy": "download-button" },
        on: { click: _vm.showQRCode },
      },
      [
        _vm.buttonImg
          ? _c("DecodeImage", { attrs: { path: _vm.buttonImg } })
          : _vm._e(),
        _vm.buttonBackgroundImg
          ? _c("DecodeImage", {
              staticClass: "button-img",
              attrs: { path: _vm.buttonBackgroundImg },
            })
          : _vm._e(),
        _c("span", { style: _vm.buttonBeforeStyle }),
        _c("span", { staticClass: "button-title" }, [_vm._v("立即下载")]),
        _c("span", { style: _vm.buttonAfterStyle }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.QRCodeShow,
            expression: "QRCodeShow",
          },
        ],
        staticClass: "mask col_jac_center",
        attrs: { "data-cy": "mask" },
      },
      [
        _c("h3", [_vm._v("请使用手机扫码")]),
        _vm._m(0),
        _c("p", [_vm._v("请勿使用微信、QQ扫码，以免无法访问")]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "qrcode_box" }, [
      _c("div", { staticClass: "qrcode", attrs: { id: "js_qrcode" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }