<template>
  <div class="wrapper">
    <template v-if="iosButtonImg">
      <a
        v-for="(btn) in activeButtons"
        :data-clipboard-text="clipboardText"
        :data-clipboard="btn.type"
        :key="btn.type"
        class="brand-common-btn"
        target="_blank"
        rel="noreferrer"
        @click="onBtnClick(btn)"
        :style="buttonStyle"
      >
        <DecodeImage
          class="button-img"
          :path="iosButtonImg"
        />
      </a>
    </template>
    <div v-else class="wrapper__buttons">
      <a
        :data-cy="'download-button-' + btn.type"
        v-for="(btn, index) in activeButtons"
        :data-clipboard-text="clipboardText"
        :data-clipboard="btn.type"
        :key="btn.type"
        class="brand-common-btn"
        target="_blank"
        rel="noreferrer"
        @click="onBtnClick(btn)"
        :style="buttonStyle"
      >
        <DecodeImage
          v-if="index === 0"
          :path="downloadRecommendTagImg"
          class="hot-recommend"
        />
        <DecodeImage
          v-if="buttonImg[btn.type]"
          class="button-img"
          :path="buttonImg[btn.type]"
        />
        <DecodeImage
          v-if="buttonBackgroundImg"
          class="button-img"
          :path="buttonBackgroundImg"
        />
        <span :style="buttonBeforeStyle" />
        <span class="button-title"> {{ btn.title }}</span>
        <span :style="buttonAfterStyle" />
      </a>
    </div>
    <KindTitps
      :appLogoIcon="appLogoIcon"
      :logo-icon="logoIcon"
      v-show="showTitp"
      @hide="showTitp = false"
      @btnClick="onDefaultBtnClick"
      :ios-tips="iosTips"
    />
  </div>
</template>

<script>
import {
  IOS_WARN,
  IOS_H5,
  IOS_DEFAULT,
  CLICK_DOWNLOAD,
  IOS_DEFAULT_TITPS,
} from '@/../config/index'
import { IOS_BUTTON_TYPES } from '@/../config/ios_button_types'
import { getQueryValues, uploadDownloadEvent } from '@/../utils'
import { getIOSActiveButtons } from '@/service'
import KindTitps from './KindTitps.vue'
import Clipboard from 'clipboard'
import dayjs from 'dayjs'
import DecodeImage from '@/components/common/DecodeImage.vue'
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  components: { DecodeImage, KindTitps },
  props: {
    downloadRecommendTagImg: {
      type: String,
      default: '',
    },
    appCode: {
      type: String,
      default: 'apple',
    },
    showWarn: {
      type: Boolean,
      default: true,
    },
    warnStyle: {
      type: Object,
      default: () => ({}),
    },
    arrowSrc: {
      default: '',
    },
    recStyle: {
      type: Object,
      default: () => ({}),
    },
    logoIcon: {
      default: '',
    },
    titleStyle: {
      type: Object,
      default: () => ({}),
    },
    titpStyle: {
      type: Object,
      default: () => ({}),
    },
    buttonStyle: {
      type: Object,
      default: () => ({}),
    },
    buttonImg: {
      type: Object,
      default: () => ({}),
    },
    buttonBeforeStyle: {
      type: Object,
      default: () => ({}),
    },
    buttonAfterStyle: {
      type: Object,
      default: () => ({}),
    },
    codeStyle: {
      type: Object,
      default: () => ({}),
    },
    copyStyle: {
      type: Object,
      default: () => ({}),
    },
    textStyle: {
      type: Object,
      default: () => ({}),
    },
    apiHosts: {
      type: Array,
      default: () => [],
    },
    iosTips: {
      type: String,
      default: IOS_DEFAULT_TITPS,
    },
    buttonBackgroundImg: {
      type: String,
      default: '',
    },
    iosButtonImg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      IOS_WARN,
      IOS_H5,
      IOS_DEFAULT,
      activeButtons: [],
      tipsShow: true,
      channelQuery: '',
      clipboardText: '',
      password: '',
      showTitp: false,
      appLogoIcon: '',
    }
  },
  created() {
    this.getActiveButtons()
    this.isShareChannel()
  },
  computed: {
    btnClass() {
      return this.activeButtons.length === 1 ? 'item-1' : ''
    },
  },
  methods: {
    async getActiveButtons() {
      const { data } = await getIOSActiveButtons(this.appCode)
      const btns = []
      let btnTf = null
      let btnDefault = null
      let filterType
      data.items.forEach((item) => {
        let button = null
        IOS_BUTTON_TYPES.some((btn) => {
          return btn.type === item.btn_type && (button = { ...item, ...btn })
        })
        if (item.btn_type === IOS_DEFAULT) {
          // this.showCode = true
          this.appLogoIcon = item.image
          btnDefault = button
        }
        if (button) {
          btns.push(button)
        }
      })
      if (btnDefault && btnTf) {
        filterType = IOS_H5
      }
      this.activeButtons = btns.filter((btn) => btn.btn_type != filterType)
      this.$nextTick(() => {
        this.addClipboard()
        this.$emit('rendered', this.activeButtons)
      })
    },
    isShareChannel() {
      let { channel_code, invite_code } = getQueryValues()
      if (channel_code === 'share') {
        this.channelQuery = `?channelCode=share&invitation_code=${invite_code}`
      } else {
        this.channelQuery = `?channelCode=${channel_code}`
      }
    },
    addClipboard() {
      const clipboardJson = {
        ...getQueryValues(),
        domain: this.apiHosts,
        app_code: this.appCode,
      }
      this.clipboardText = JSON.stringify(clipboardJson)
      const clipboard = new Clipboard('[data-clipboard=iOS_default]')
      clipboard.on('success', (e) => {
        e.clearSelection()
      })
    },
    onDefaultBtnClick() {
      this.toTargetUrl(this.defaultBtn)
    },
    onBtnClick(btn) {
      uploadDownloadEvent(this.appCode, CLICK_DOWNLOAD, btn.type)
      if (btn.type === IOS_DEFAULT) {
        this.showTitp = true
        this.defaultBtn = btn
      } else {
        this.toTargetUrl(btn)
      }
    },
    toTargetUrl(btn) {
      const href = btn.download_url
        ? btn.download_url
        : `${window.location.origin}/${btn.link}${this.channelQuery}`
      const arg =
        '\u003cscript\u003esetTimeout(function(){location.replace("' +
        href +
        '")}, 500)\u003c/script\u003e'
      window.open('javascript:window.name;', arg)
    },
  },
}
</script>
<style scoped>
.wrapper__buttons {
  width: 85%;
  display: flex;
  justify-content: center;
  column-gap: 10px;
  margin: auto;
}

.brand-common-btn {
  flex-grow: 1;
  position: relative;
  height: 45px;
  padding: 0;
  font-size: 20px;
}

.hot-recommend {
  position: absolute;
  width: auto;
  max-height: 40px;
  min-height: 20px;
  height: 28px;
  right: -12px;
  top: -17px;
  z-index: 2;
}

.button-img {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.button-title {
  z-index: 3;
}
</style>
